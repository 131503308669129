<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal width-table-modal">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title"> {{ order_type_id.value.order_type == 'Dine In' ? 'DINE IN ORDER' : order_type_id.value.order_type == 'Online' ? 'ONLINE ORDER' : 'DELIVERY ORDER'}}</span>
                <span @click="cancelOrderType()">
                    <i class="icon icon-close pointer text-white"></i>
                </span>
            </div>
            <div class="v-modal-body">
                <div class="v-modal-layout">
                    <div class="order-type-border" v-if="order_type_id.value.order_type == 'Deliveries' || order_type_id.value.order_type == 'Online'">
                        <div class="d-flex align-items-center orderTypeGreen ml-4">
                            <img v-if="order_type_id.value.order_type == 'Deliveries'" src="https://storage.googleapis.com/5e8dbf9ac9ece9d5bf0786f0/0d93eb61-c85c-4e31-b0f6-e55d496b9b11.png" alt="" class="pl-2 pr-2 w-46">
                            <img v-if="order_type_id.value.order_type == 'Online'" src="https://storage.googleapis.com/5e8dbf9ac9ece9d5bf0786f0/16298ee6-d1a7-4ef8-a791-e346e6995e10.png" alt="" class="pl-2 pr-2 w-46">
                            <span class="ml-1 fw-500">{{order_type_id.value.order_type}}</span>
                        </div>
                        <div v-if="delivery_companies.length == 0" class="fs-14 py-5 text-center">
                            Delivery Companies Not Found.
                        </div>
                        <div v-else class="d-flex align-items-center flex-wrap">
                            <div v-for="delivery in delivery_companies" :key="delivery._id">
                                <span @click="selectDeliveryCo(delivery.name, delivery.image)">
                                        <div class="orderTypeNew" :class="{'selectedBackground':selected_delivery_type == delivery.name}">
                                            <avatar
                                                class="order-avatar pt-6"
                                                v-if="delivery.image == ''"
                                                :username="delivery.name.replace(/ .*/,'')"
                                                :src="delivery.image"
                                                :size="70"
                                                :rounded="false"
                                                color="#00448b"
                                                background-color="#00448b">
                                            </avatar>
                                            <img :src="delivery.image" class="deliveryImage" :alt="delivery.name" v-else>
                                        </div>
                                        <div class="font-weight-bold text-center pt-3">{{delivery.name}}</div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="order-type-border" v-if="order_type_id.value.order_type == 'Dine In'">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <img src="https://storage.googleapis.com/5e8dbf9ac9ece9d5bf0786f0/6b3e5bfa-7a60-41aa-a109-49d36e67a30e.png" alt="" class="pl-2 pr-2 w-46">
                                <span class="text-secondary fw-600">Table No. : 
                                    <template v-if="order_type_id.order.tables[0]">
                                        <span class="table_no text-truncate" :title="order_type_id.order.tables[0]['name']" v-if="order_type_id.order.tables && order_type_id.order.tables.length != 0 && order_type_id.order.tables[0].name" style="width: 110px;">
                                            <span>{{ order_type_id.order.tables[0]['name'] }}</span>
                                        </span>
                                        <span class="table_no text-truncate" :title="order_type_id.order.tables[0]" v-else-if="order_type_id.order.tables && order_type_id.order.tables[0] &&  !order_type_id.order.tables[0]['name']" style="width: 110px;">
                                            <span>{{ order_type_id.order.tables[0]}}</span>
                                        </span>
                                    </template>
                                    <template v-else>
                                        <span>-</span>
                                    </template>
                                </span>
                            </div>
                            <div>
                                <span class="fw-600 fs-13 pr-3">No. of  Guests :</span>
                                <div class="form-check form-check-inline" style="padding-left:5px !important;">
                                    <span class="pointer">
                                        <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" @click="decrementPax()" style="width: 29px;"></span>
                                    <label class="form-check-label" style="padding-left: 5px !important;">
                                        <input class="no-for-pax" style="padding-left: 5px;" type="text"  v-on:keypress="isNumber($event)" min="1" v-model.number="no_of_pax" name="pax" v-validate="'required|numeric|min_value:1'" max="2" maxlength="2" id="no_of_pax"  />
                                    </label>
                                    <span class="pl-1 pointer"><img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" @click="incrementPax()" style="width: 29px;"></span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <div class="m-input-icon m-input-icon--left m-input-icon--right ml-0 mt-1">
                                <input type="text" class="form-control m-input m-input--pill" placeholder="Search For Tables" v-model="search_key" @input="getTables()" autocomplete="off"/>
                                <span class="m-input-icon__icon m-input-icon__icon--left">
                                    <span>
                                        <i class="icon icon-magnify"></i>
                                    </span>
                                </span>
                                <span class="m-input-icon__icon m-input-icon__icon--right">
                                    <span>
                                        <i v-if="search_key.length" @click="search_key = '';getTables()" class="icon icon-close-circle" style="padding-top: 1px;!important;font-size:20px !important;color:#cdd2da !important;cursor:pointer !important;"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="digi-table-loader d-flex justify-content-center" v-if="loadAllTables" style="margin: 0 auto">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div v-else class="d-flex align-items-center flex-wrap overflow-tables" style="padding-bottom: 100px;">
                            <div v-for="(t,index) in tables" :key="index">
                                <span v-if="!disableProgress" :key="index" :class="{'availableTable': t.status == 'Available','occupiedTable': t.status == 'Occupied','occupiedSelectedTable':t.selected == true || t.status=='inprogress','inProgressTable':t.status == 'inprogress' && t.selected == false,'moveSelectedTable':t.order_id == selectedProducts.order_id}" @click="toggleSelectTable(index)">
                                    <i v-if="t.selected" class="text-white icon-check s-4 selected-card"></i>
                                    {{t.name}}
                                    <span class="text-white text-capitalize">
                                        {{t.status}}
                                    </span>
                                    <span v-if="t.is_merged || (t.status=='inprogress' && t.is_merged) && t.merged_with" class="merge-Table">
                                        Merged With {{t.merged_with}}
                                    </span>
                                    <span v-else-if="t.selected && selectedTable.length > 1">+ {{selectedTable.length -1}} More</span>
                                    <span v-else-if="t.selected && selectedTable.length == 1 && t.number_of_tables"></span>
                                    <span v-else-if="t.is_primary && t.number_of_tables != 0 && t.status !='Available'">
                                        <span v-if="t.number_of_tables != 0">+</span>
                                        <span v-if="t.number_of_tables != 0"> {{t.number_of_tables}} </span>
                                        <span v-if="t.number_of_tables != 0">More</span>
                                    </span>
                                </span>
                                <span v-else-if="disableProgress" :key="index" :class="{'availableTable': t.status == 'Available','occupiedTable': t.status == 'Occupied' && t.order_id != selectedProducts.order_id,'occupiedSelectedTable':t.selected == true || t.status=='inprogress','inProgressTableDisable':t.status == 'inprogress' && t.selected == false,'moveSelectedTable':t.order_id == selectedProducts.order_id}" @click="toggleSelectTable(index)">
                                    <i v-if="t.selected " class="text-white icon-check s-4 selected-card"></i>
                                    {{t.name}}
                                    <span class="text-white text-capitalize">
                                        {{t.status}}
                                    </span>
                                    <span v-if="t.is_merged || (t.status=='inprogress' && t.is_merged) && t.merged_with " class="merge-Table">
                                        Merged With {{t.merged_with}}
                                    </span>
                                    <span v-else-if="t.selected && selectedTable.length > 1">+ {{selectedTable.length -1}} More</span>
                                    <span v-else-if="t.selected && selectedTable.length == 1 && t.number_of_tables"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="v-modal-footer text-center pt-5 pb-5">
                <a class="btn btn-danger btn-smm text-uppercase ml-1" :disabled="disable_submit" @click="cancelOrderType()">CANCEL</a>
                <button class="btn btn-secondary btn-smm text-uppercase ml-3" @click="updateOrder()" :disabled="disable_submit">SUBMIT
                    <half-circle-spinner v-if="disable_submit" :animation-duration="1000" :size="20" color="#00448b" style="position: absolute;top: 6px;right: 2px;" />
                </button>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{ success_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ warning_msg }}</sweet-modal>
        <order-type-change-success v-if="order_type_change_success" :delivery_name="delivery_name_for_popup" :delivery_image="delivery_image_for_popup" @hideSuccessPopup="hideSuccessPopup" modal_name="order-type-change-success" :order_type="what_order_type"></order-type-change-success>
    </modal>
</template>
<script>
    import orders from "./mixins/orders.js"
    import { SweetModal } from "sweet-modal-vue";
    import Avatar from 'vue-avatar';
    const OrderTypeChangeSuccess = () => import('./OrderTypeChangeSuccess');
    import { HalfCircleSpinner } from "epic-spinners";
    export default {
        props: ['modal_name',"order_type_id","order_id", "what_order_type_from_checkout"],
        mixins: [orders],
        data() {
            return {
                success_msg: null,
                warning_msg: null,
                delivery_companies: [],
                disableProgress: false,
                search_key:'',
                ajaxcall:true,
                loadAllTables: false,
                selectedTable: [],
                table_document: {},
                tables: [],
                selected_table_names: [],
                selectedProducts:{},
                no_of_pax: 1,
                selected_delivery_type:'',
                order_type_change_success: false,
                what_order_type: '',
                delivery_name_for_popup: '',
                delivery_image_for_popup: '',
                disable_submit:false,
            }
        },
        components:{
            SweetModal,
            Avatar,
            OrderTypeChangeSuccess,
            HalfCircleSpinner
        },
        methods: {
            isNumber: function (evt, index) {
                evt = evt ? evt : window.event
                var charCode = evt.which ? evt.which : evt.keyCode
                if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                    evt.preventDefault()
                } else {
                    return true
                }
            },
            hideSuccessPopup() {
                this.order_type_change_success = false;
                setTimeout(() => {
                    this.$modal.hide('order-type-change-success')
                }, 500);
            },
            async updateOrder() {
                this.disable_submit = true;
                this.what_order_type = this.order_type_id.value.order_type;
                let check_selected_table = false
                let check_delivery_company = false
                if(this.order_type_id.value.order_type == 'Dine In'){
                    if(this.selectedTable.length == 0){
                        check_selected_table = true
                    }
                    else{
                        check_selected_table = false
                    }
                }
                if(this.order_type_id.value.order_type == 'Deliveries' || this.order_type_id.value.order_type == 'Online'){
                    if(this.selected_delivery_type == ' '){
                        check_delivery_company = true
                    }
                    else{
                        check_delivery_company = false
                    }
                }
                if(!check_selected_table && !check_delivery_company){
                    this.selectedTable.forEach(element => {
                        if (typeof element === 'object') {
                            this.selected_table_names.push(element.name)
                        } else {
                            this.selected_table_names.push(element)
                        }
                    })
                    let params = {
                        order_id: this.order_id,
                        order_type: this.order_type_id.value.order_type,
                        tables: this.selected_table_names,
                        delivery_company: this.selected_delivery_type,
                        no_of_pax: this.no_of_pax
                    }
                    let response = await this.updateOrderTypeDetails(params)
                    if(this.order_type_id.value.order_type == 'Dine In' || this.order_type_id.value.order_type == 'dine in') {
                        this.success_msg = "Order Type Updated Successfully"
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            // this.$modal.hide(this.modal_name)
                            this.$emit("updateData")
                            this.$emit("updateTablePax", {tableName:this.selected_table_names, no_of_pax: this.no_of_pax})
                        }, 3000);
                    } else {
                        this.order_type_change_success = true;
                        setTimeout(() => {
                            this.$modal.show('order-type-change-success')
                        }, 500);
                        setTimeout(() => {
                            this.hideSuccessPopup();
                            // this.$modal.hide(this.modal_name)
                            this.$emit("updateData")
                        }, 3000);
                    }
                }
                else if(check_selected_table){
                    this.$toasted.global.error("Please Select Table")
                    this.disable_submit = false;
                }
                else if(check_delivery_company){
                    this.$toasted.global.error("Please Select Company")
                    this.disable_submit = false;
                }
            },
            cancelOrderType() {
                this.$modal.hide(this.modal_name)
                this.$emit('cancelOrderType')
            },
            incrementPax() {
                if (this.no_of_pax == 1000) {
                    return
                }
                this.no_of_pax = Number(this.no_of_pax) + 1
            },
            decrementPax() {
                if (this.no_of_pax == 1) {
                    return
                }
                this.no_of_pax = Number(this.no_of_pax) - 1
            },
            async loadDeliverCompanies(){
                let params = {
                    search_key: "",
                    order_type: this.order_type_id.value._id,
                    sort_key: "name",
                    sort_value: 1,
                    required_id: true
                }
                let response = await this.orderDeliveryCompanies(params)
                this.delivery_companies = response.response.delivery_companies
            },
            toggleSelectTable(index) {
                if(!this.disableProgress){
                    if (this.tables[index].status == 'inprogress' && !this.editTable && this.tables[index].is_primary && this.selectedTable.length == 0) {
                        // this.confirmInprogress(index)
                        return
                    } else if (this.tables[index].status == 'Occupied' && !this.tables[index].selected) {
                        return
                    } else if (this.tables[index].status == 'inprogress' && this.order_id != this.tables[index].order_id) {
                        return
                    } else if (this.tables[index].status == 'inprogress' && this.selectedTable.length && this.tables[index].order_id) {
                        if (this.selectedTable[0] == this.tables[index]['name'] || this.selectedTable[0]['name'] == this.tables[index]['name']) {
                            this.removePrimaryTable()
                            return
                        } else if (this.get_matched_table(index)) {
                            if (this.tables[index].status == 'inprogress') {
                                this.selectedTable.splice(this.selectedTable.indexOf(this.tables[index]['name']),1)
                            } else {

                                this.selectedTable.splice(this.selectedTable.indexOf(this.tables[index]['name']),1)
                            }

                            this.tables[index]['is_merged'] = false
                            this.tables[index]['merged_with'] = ''
                            this.tables[index]['selected'] = false
                            this.tables[index]['status'] = 'Available'

                            return
                        } else {
                            return
                        }
                    } else if (this.tables[index].status == 'inprogress' && this.tables[index].is_primary && !this.inProgressOrder) {
                        this.order['_id'] = this.tables[index]['order_id']
                        this.order_id = this.tables[index]['order_id']
                        this.selected_table_names = this.tables[index]['merged_tables']
                        this.order.type = 'Dine In'
                        this.inProgressOrder = true
                        this.editOrderTable = true
                        this.updateTable()
                        return
                    } else if (this.selectedTable[0] == this.tables[index]['name'] && this.inProgressOrder) {
                        this.removePrimaryTable()
                        return
                    } else if (this.tables[index].status == 'inprogress' && !this.tables[index].is_primary) {
                        return
                    }
                    if (this.selectedTable.length) {
                        if (this.selectedTable[0]['_id'] == this.tables[index]['_id'] || this.selectedTable[0] == this.tables[index]['name']) {
                            // return
                            this.removePrimaryTable()
                            return
                        }
                        for (var i = 0; i < this.selectedTable.length; i++) {
                            if (this.selectedTable[i]['_id'] == this.tables[index]['_id'] || this.selectedTable[i] == this.tables[index]['name']) {
                                if (typeof this.selectedTable[i] == 'object') {
                                    this.selectedTable[i]['selected'] = false
                                }
                                this.tables[index]['is_merged'] = false
                                this.tables[index]['merged_with'] = ''
                                this.tables[index]['selected'] = false
                                this.tables[index]['status'] = 'Available'
                                this.selectedTable.splice(i, 1)
                                return
                            } else {
                                this.tables[index]['is_merged'] = true
                                this.tables[index]['selected'] = true
                                if (this.selectedTable[0]['name']) {
                                    this.tables[index]['merged_with'] = this.selectedTable[0]['name']
                                } else {
                                    this.tables[index]['merged_with'] = this.selectedTable[0]
                                }
                            }
                        }
                    }
                    this.table_document = this.tables[index]
                    this.table_document.selected = true
                    this.selectedTable.push(this.table_document)
                    return
                }
                else{
                    this.moveTable(index)
                }
            },
            async moveTable(index){
                if (index == -1) {
                    if(this.selectedTable.length == 0 && this.takeAwayId == ''){
                        this.takeAwayId = this.selectedProducts.order_id
                    }
                    else if(this.takeAwayId !=''){
                        this.deselectTakeAwayTable()
                    }
                } else if(this.tables[index].status == 'Occupied' && this.tables[index].order_id != this.selectedProducts.order_id) {
                    if (this.tables[index].status == 'Occupied' && !this.tables[index].selected && this.selectedTable.length == 0 && this.takeAwayId == '') {
                        this.new_order_id = this.tables[index].order_id
                    }
                    if (this.selectedTable.length==1) {
                        if (this.selectedTable[0]['_id'] == this.tables[index]['_id'] || this.selectedTable[0] == this.tables[index]['name']) {
                            this.deselectOccupiedTable()
                            return
                        }
                        for (var i = 0; i = 1; i) {
                            if (this.selectedTable[i]['_id'] == this.tables[index]['_id'] || this.selectedTable[i] == this.tables[index]['name']) {
                                if (typeof this.selectedTable[i] == 'object') {
                                    this.selectedTable[i]['selected'] = false
                                }
                                this.tables[index]['status'] = 'Occupied'
                                this.selectedTable.splice(i, 1)
                                return
                            }
                        }
                    }
                    if(this.selectedTable.length == 0 && this.takeAwayId == ''){
                        this.table_document = this.tables[index]
                        this.table_document.selected = true
                        this.selectedTable.push(this.table_document)
                    }
                }
                else if(this.tables[index].status == 'Available' && this.tables[index].order_id != this.selectedProducts.order_id){
                    if (this.selectedTable.length && this.new_order_id == '' && this.takeAwayId == '') {
                        if (this.selectedTable[0]['_id'] == this.tables[index]['_id'] || this.selectedTable[0] == this.tables[index]['name']) {
                            this.removePrimaryTable()
                            return
                        }
                        for (var i = 0; i < this.selectedTable.length; i++) {
                            if (this.selectedTable[i]['_id'] == this.tables[index]['_id'] || this.selectedTable[i] == this.tables[index]['name']) {
                                if (typeof this.selectedTable[i] == 'object') {
                                    this.selectedTable[i]['selected'] = false
                                }
                                this.tables[index]['is_merged'] = false
                                this.tables[index]['merged_with'] = ''
                                this.tables[index]['selected'] = false
                                this.tables[index]['status'] = 'Available'
                                this.selectedTable.splice(i, 1)
                                return
                            } else {
                                this.tables[index]['is_merged'] = true
                                this.tables[index]['selected'] = true
                                if (this.selectedTable[0]['name']) {
                                    this.tables[index]['merged_with'] = this.selectedTable[0]['name']
                                } else {
                                    this.tables[index]['merged_with'] = this.selectedTable[0]
                                }
                            }
                        }
                    }
                    if(this.new_order_id == '' && this.takeAwayId == ''){
                        this.table_document = this.tables[index]
                        this.table_document.selected = true
                        this.selectedTable.push(this.table_document)
                    }
                }
            },
            getTables() {
                this.loadAllTables = true
                this.tables = []
                this.$http.post('/orders/get_all_tables', {search_key:this.search_key }).then(res => {
                    if (res.data.status_id == 1) {
                        this.tables = res.data.message
                        if(this.selectedTable.length){
                            let value = this.selectedTable[0]._id
                            this.tables.forEach((el,index)=>{
                                if(el._id == value){
                                    this.tables[index]['selected'] = true
                                }
                            })
                            if(this.selectedTable.length > 1){
                                this.tables.forEach((el,index)=>{
                                    this.selectedTable.forEach((s,si)=>{
                                        if(s.is_merged){
                                            if(el._id == s._id){
                                                this.tables[index]['is_merged'] = true
                                                this.tables[index]['merged_with'] = this.selectedTable[0].name
                                                this.tables[index]['selected'] = true
                                            }
                                        }
                                    })
                                })
                            }
                        }
                        this.loadAllTables = false
                    } else {
                        this.$toasted.global.error(res.data.reason)
                        this.loadAllTables = false
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason
                    this.$refs.warning_modal.open()
                    this.loadAllTables = false
                })
            },
            removePrimaryTable() {
                this.$swal({
                    title: 'Alert?',
                    text: 'Are you sure you want to de-select the selected tables ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        if (this.selectedTable) {
                            this.removeTables()
                        }
                        this.order._id = this.order_id
                        this.updateOrderType()
                        if (!this.editTableFromSummary) {
                            this.takeOrder(this.order_id)
                        }
                        EventBus.$emit('UpdateOrderType')
                        EventBus.$emit('CloseTableSelection', this.order.type)
                    }
                })
            },
            removeTables() {
                for (var i = 0; i < this.selectedTable.length; i++) {
                    for (var j = 0; j < this.tables.length; j++) {
                        if (this.selectedTable[i]['name'] == this.tables[j]['name'] || this.selectedTable[i] == this.tables[j]['name']) {
                            this.tables[j]['selected'] = false
                            this.tables[j]['is_merged'] = false
                            this.tables[j]['merged_with'] = ''
                            this.tables[j]['status'] = 'Available'
                            if (typeof this.selectedTable[i] == 'object') {
                                this.selectedTable[i]['selected'] = false
                                this.selectedTable[i]['is_merged'] = false
                                this.selectedTable[i]['merged_with'] = ''
                            }
                        }
                    }
                }
                this.selectedTable.splice(0, this.selectedTable.length)
                this.selectedTable.splice(0, this.selected_table_names.length)
            },
            deselectTakeAwayTable(){
                this.$swal({
                    title: 'Alert?',
                    text: 'Are you sure you want to de-select the selected tables ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        this.takeAwayId = ''
                    } else {
                        return
                    }
                })
            },
            deselectOccupiedTable(){
                this.$swal({
                    title: 'Alert?',
                    text: 'Are you sure you want to de-select the selected tables ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        if (this.inProgressOrder && !this.editTable) {
                            this.$http.post('/orders/discard', { orderId: this.order_id }).then(res => {
                                if (res.data.status_id == 1) {
                                    this.$toasted.global.success('Order Discarded Successfully')
                                    this.$emit('discard', true)
                                    this.order_id = ''
                                    this.order._id = null
                                    this.orderId = null
                                    this.new_order_id = ''
                                    this.selectedTable = []
                                    this.selected_table_names = []
                                    this.getTables()
                                } else {
                                    this.$toasted.global.error(res.data.reason)
                                }
                            })
                            this.removeOccupiedTables()
                        } else {
                            this.removeOccupiedTables()
                        }
                    } else {
                        return
                    }
                })
            },
            confirmInprogress(index) {
                this.$swal({
                    title: 'Order Inprogress',
                    text: 'Order is in Progress. Do You Wish to Continue ?',
                    type: 'warning',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'PROCEED',
                    cancelButtonText: 'DISCARD',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                    this.selected_table_names = this.tables[index]['merged_tables']
                    this.inProgressOrder = true
                    this.editOrderTable = true
                    this.updateTable()
                    } else if (result.dismiss != 'close') {
                        this.$http.post('/orders/discard', {orderId: this.tables[index].order_id}).then(res => {
                            if (res.data.status_id == 1) {
                                this.$toasted.global.success('Order Discarded Successfully')
                                this.$emit('discard', true)
                                this.selectedTable = []
                                this.selected_table_names = []
                                this.getTables()
                                return
                            }else{
                                this.$toasted.global.error(res.data.reason)
                            }
                        })
                    }
                })
            },
            removeOccupiedTables() {
                for (var i = 0; i < this.selectedTable.length; i++) {
                    for (var j = 0; j < this.tables.length; j++) {
                        if (this.selectedTable[i]['name'] == this.tables[j]['name'] || this.selectedTable[i] == this.tables[j]['name']) {
                            this.tables[j]['selected'] = false
                            this.tables[j]['is_merged'] = false
                            this.tables[j]['merged_with'] = ''
                            this.tables[j]['status'] = 'Occupied'
                            if (typeof this.selectedTable[i] == 'object') {
                                this.selectedTable[i]['selected'] = false
                                this.selectedTable[i]['is_merged'] = false
                                this.selectedTable[i]['merged_with'] = ''
                            }
                        }
                    }
                }
                this.new_order_id = ''
                this.selectedTable.splice(0, this.selectedTable.length)
                this.selectedTable.splice(0, this.selected_table_names.length)
            },
            selectDeliveryCo(value, image){
                this.delivery_name_for_popup = value
                this.delivery_image_for_popup = image
                this.selected_delivery_type = value
            }
        },
        mounted(){
            if(this.what_order_type_from_checkout == 'Dine In'){
                this.getTables()
            }
            else if(this.what_order_type_from_checkout == 'Online' || this.what_order_type_from_checkout == 'Deliveries'){
                this.loadDeliverCompanies()
            }
            this.no_of_pax = this.order_type_id.order.no_of_pax ? this.order_type_id.order.no_of_pax : 1
            console.log(this.order_type_id)
        }
    }
</script>
<style scoped>
    .moveSelectedTable{
        background-color:#94bef0 !important;
        cursor: not-allowed;
        width: 105px;
        height: 100px;
        font-weight: 600;
        padding-top: 36px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    }
    .legend-selected {
        border-radius: 100%;
        background-color: #1dc75d;
        margin-right: 8px !important;
    }
    .tableselection-header {
        background: #fff;
        height: 110px;
        border-bottom: 1px solid #eee;
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 99999;
    }
    .handelHeader{
        height: 65px !important;
    }
    .box p {
        margin-top: 12px;
    }
    .box-avaliable {
        height: 80px;
        border-radius: 7px;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background: #f9a00b;
        background: -webkit-linear-gradient(
            to bottom,
            #f9a00b,
            #edde5d
        );
        background: linear-gradient(
            to bottom,
            #f5a623,
            #ffd184
        );
        margin: 0;
        padding: 4px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        margin: 15px;
        cursor: pointer;
    }
    .box-occupied {
        height: 80px;
        border-radius: 7px;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(
            to bottom,
            #00448b,
            #00448b 51%,
            #69b3fc,
            #9fcbff
        );
        margin: 0;
        padding: 4px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        margin: 15px;
        cursor: pointer;
    }
    .takeAway {
        cursor: pointer;
        width: 105px;
        height: 100px;
        padding-top: 19px !important;
        padding: 13px;
        font-weight: 600 !important;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(
            to bottom,
            #1dc75d,
            #1ed262 51%,
            #17e264,
            #9fffac
        );
    }
    .deliveriesTable {
        cursor: pointer;
        width: 105px;
        height: 100px;
        padding-top: 16px !important;
        padding: 2px;
        font-weight: 600 !important;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(
            to bottom,
            #1dc75d,
            #1ed262 51%,
            #17e264,
            #9fffac
        );
    }
    .admin-card {
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        width: 120px;
        height: 90px;
        border-radius: 15px;
        color: #fff;
        padding: 0;
        margin: 0;
        margin-bottom: 15px;
        background-image: linear-gradient(
            to bottom,
            #f9a00b,
            #f9a00b 51%,
            #ffd184,
            #9fcbff
        );
    }
    .available-select {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
        background-color: #f9a00b;
        margin-top: 3px !important;
        margin-right: 8px !important;
    }
    .occupied-select {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
        background-color: #00448b;
        margin-top: 3px !important;
        margin-right: 8px !important;
    }
    .inprogress-select {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
        margin-top: 3px !important;
        margin-right: 8px !important;
    }
    .select-seats {
        display: inline-flex;
        padding-right: 24px;
        cursor: pointer;
        color: #00448b !important;
        font-weight: 600;
    }
    .table-label {
        color: #303030;
        font-weight: 600;
    }
    .availableTable {
        cursor: pointer;
        width: 105px;
        height: 100px;
        font-weight: 600;
        padding-top: 36px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #f9a00b, #f9a00b 51%, #ffd184);
    }
    .occupiedTable {
        cursor: not-allowed !important;
        width: 105px;
        height: 100px;
        font-weight: 600;
        padding-top: 36px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
    }
    .availableTable.occupiedSelectedTable {
        cursor: pointer;
        width: 105px;
        height: 100px;
        font-weight: 600;
        padding-top: 26px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
    }
    .occupiedSelectedTable {
        cursor: pointer;
        width: 105px;
        height: 100px;
        font-weight: 600;
        padding-top: 36px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
    }
    .inProgressTable {
        cursor: not-allowed !important;
        width: 105px;
        height: 100px;
        font-weight: 600;
        padding-top: 28px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
    }
    .inProgressTableDisable{
        cursor: not-allowed !important;
        width: 105px;
        user-select: none;
        height: 100px;
        font-weight: 600;
        pointer-events: none;
        padding-top: 28px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        opacity: 0.5;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
    }
    .merge-Table {
        font-size: 12px;
        font-weight: 600;
        margin-top: 0px;
    }
    .selected-card {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 13px !important;
        border-radius: 100px;
        margin-top: -20px;
        background-color: #4caf50 !important;
        margin-left: 32px;
        z-index: 9999;
    }
    .orderNotification {
        width: 17px;
        height: 17px;
        display: -webkit-box;
        position: relative;
        left: 52px;
        bottom: 52px;
        -ms-flex-align: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 11px !important;
        border-radius: 100px;
        background-color: #ff3b3b !important;
    }
    .swal2-popup .swal2-close{
        color: #00448b !important;
    }
    .orderTypeGreen{
        border-radius: 5px !important;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24) !important;
        border: solid 0.5px #1dc75d !important;
        background-color: #1dc75d !important;
        color: #ffff !important;
        white-space: nowrap !important;
        cursor: pointer !important;
        height: 36px !important;
        margin-right: 15px;
        font-weight: 600;
        font-size: 15px;
        width: 150px;
        line-height: 2;
    }
    .orderTypeDisabled{
        pointer-events: none !important;
        opacity: 0.5 !important;
    }
    .borderBottomDashed{
        border-bottom:2px dashed #707070 !important
    }
    .orderTypeselected{
        background: #00448b !important;
        border:0.5px solid #00448b !important;
    }
    .no-orders-row {
        margin: 0 auto;
        top: 30%;
        position: relative;
        text-align: center;
    }
    .fw-600{
        font-weight: 600 !important;
    }
    .cardNew{
        background-color: rgb(237, 238, 239) !important;
        border-top: 3px solid #00448b !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        box-shadow: none !important;
    }
    .nameTextTruncate{
        max-width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .selectedBackground{
        box-shadow: 0 1px 6px 0 rgba(28, 141, 255, 0.34);
        background: #0e9f45 !important;
    }
    .deliveryImage{
        width: 70px;
        height: 70px;
        margin-top: -20px;
        border-radius: 8px;
        margin-left: 8px;
    }
    .w-30{
        width: 30px !important;
    }
    .w-25{
        width: 25px;
    }
    .w-28{
        width: 28px !important;
    }
    .w-46{
        width: 46px !important;
    }
    .w-42{
        width: 42px !important;
    }
    .w-40{
        width: 40px !important;
    }
    .orderTypeNew {
        cursor: pointer;
        width: 105px;
        height: 100px;
        font-weight: 600;
        padding-top: 36px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #dfe8f4, #dfe8f4 51%, #dfe8f4);
    }
    .whiteSpace{
        white-space: nowrap;
    }
    .order-type-border{
        border: solid 1px #206ad3;
        border-radius: 6px;
        padding: 14px;
        background-color: #fff;
    }
    .overflow-tables{
        max-height: 400px;
        overflow: auto
    }
</style>
